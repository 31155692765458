<template>
  <div>
    <el-carousel height="676px">
      <el-carousel-item>
        <div class="homeBan">
          <div class="container metarace_wrap">
            <img
              v-lazy="require('@/assets/images/homeHouse.png')"
              alt
              class="homeHouse"
            />

            <div class="metaraceRight">
              <p class="metaraceTit">METARACE®</p>
              <p class="metaExplain">
                <span class="colorText"
                  >METARACE®— horse racing is the first Metaverse competitive
                  game that is live on the metarace gaming platform, and
                  equipped with ‘ play-to-earn ‘ feature.</span
                >
                MetaRace built on the blockchain to simulate real-life racing
                experiences. MetaRace® brings horse racing into a futuristic
                virtual reality. Players compete with each other to win and earn
                coins, upgrade the mechanics of their horse, or sit on the
                sidelines and place a wager!
              </p>

              <div class="startBtn" @click="toGame('/start')">START</div>
              <!-- <div class="startBtn" @click="toGame('/game')">START</div> -->
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div class="video_wrap">
      <video
        src="@/assets/images/game.mp4"
        poster="@/assets/images/videoBg.jpeg"
        ref="videos"
        controls
      />
      <img
        v-lazy="require('@/assets/images/action.png')"
        alt=""
        class="action"
        @click="actionIconClick"
        v-show="show"
      />
    </div>
    <div class="container gameplay" id="Gameplay">
      <div class="container carousel-wrap">
        <div class="carouselTit">3 WAYS TO PLAY AND 3 WAYS TO EARN</div>
        <div class="carouselBox">
          <el-carousel type="card" height="272px">
            <el-carousel-item>
              <img v-lazy="require('@/assets/images/gameplay1.png')" alt />
            </el-carousel-item>
            <el-carousel-item>
              <img v-lazy="require('@/assets/images/gameplay2.png')" alt />
            </el-carousel-item>
            <el-carousel-item>
              <img v-lazy="require('@/assets/images/gameplay3.png')" alt />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <HouseType />
    <div class="container gameplay">
      <div class="container carousel-wrap">
        <div class="carouselTit">3 RACE SETTINGS, 5 COMPETITION LEVELS</div>
        <div class="carouselBox">
          <el-carousel type="card" height="272px">
            <el-carousel-item>
              <img v-lazy="require('@/assets/images/race1.png')" alt />
            </el-carousel-item>
            <el-carousel-item>
              <img v-lazy="require('@/assets/images/race2.png')" alt />
            </el-carousel-item>
            <el-carousel-item>
              <img v-lazy="require('@/assets/images/race3.png')" alt />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="container goalWrap">
      <div class="goalTitle">One ultimate goal:<span>WIN</span></div>
      <div class="goalList">
        <div class="goaltem">
          <div class="imgCon">
            <img v-lazy="require('@/assets/images/winIcon1.png')" alt />
          </div>
          <div class="timeReward">WEEKLY REWARD</div>
          <div class="stay">STAY TUNED</div>
        </div>
        <div class="goaltem">
          <div class="imgCon">
            <img v-lazy="require('@/assets/images/winIcon2.png')" alt />
          </div>
          <div class="timeReward">MONTHLY REWARD</div>
          <div class="stay">STAY TUNED</div>
        </div>
        <div class="goaltem">
          <div class="imgCon">
            <img v-lazy="require('@/assets/images/winIcon3.png')" alt />
          </div>
          <div class="timeReward">YEARLY REWARD</div>
          <div class="stay">STAY TUNED</div>
        </div>
      </div>
    </div>
    <div class="container raceRank">
      <div class="rankTitle">
        <div>METARACE®</div>
        <div>CHAMPIONSHIP BILLBOARD</div>
      </div>
      <ul class="dataChange">
        <li>WEEKLY</li>
        <li>MONTHLY</li>
        <li>YEARLY</li>
      </ul>
      <div class="rankTabel">
        <div class="raceRankbg">COMING SOON</div>
        <div class="tabelHead">
          <li class="rank">RANKING</li>
          <li class="house">RACING HORSE NAME</li>
          <li class="algebra">ALGEBRA</li>
          <li class="gene">GENE</li>
          <li class="integral">INTEGRAL</li>
        </div>
        <div class="tabelBody">
          <div
            class="tabelItem"
            v-for="(item, index) in tebelData"
            :key="index"
          >
            <li v-if="index == 0">
              <img
                v-lazy="require('@/assets/images/first.png')"
                alt=""
                class="trophy"
              />
            </li>
            <li v-else-if="index == 1">
              <img
                v-lazy="require('@/assets/images/second.png')"
                alt=""
                class="trophy"
              />
            </li>
            <li v-else-if="index == 2">
              <img
                v-lazy="require('@/assets/images/third.png')"
                alt=""
                class="trophy"
              />
            </li>
            <li v-else>
              {{ index + 1 }}
            </li>
            <li>
              <img
                v-lazy="require('@/assets/images/user.png')"
                alt=""
                class="user"
              />{{ item.name }}
            </li>
            <li>{{ item.algebra }}</li>
            <li>{{ item.gene }}</li>
            <li>{{ item.num }}</li>
          </div>
        </div>
      </div>
    </div>

    <div class="doubleWrap" id="Tokenomics">
      <div class="container">
        <div class="doubleTitle">DOUBLE TOKEN CIRCULAR ECONOMY</div>
        <div class="typeCon">
          <div class="tokenType">
            <div class="leftImgBox">
              <div>TOTAL META</div>
              <div>210 MILLION</div>
            </div>
            <div class="typeData">
              <div class="textOne">MINING REWARD 35%</div>
              <div class="textTwo">CORE CONTRIBUTOR 5%</div>
              <div class="textSan">INVESTOR 15%</div>
              <div class="textFour">LABS & EARLY STAGE INVESTORS 25%</div>
              <div class="textFive">ECOSYSTEM FUND 15%</div>
              <div class="textSix">AIRDROP 5%</div>
            </div>
          </div>
          <div class="tokenType">
            <div class="leftImgBox">
              <div>TOTAL RACE</div>
              <div>10 BILLION</div>
            </div>
            <div class="typeData">
              <div class="textOne">MINING 99%</div>
              <div class="textTwo">PROVIDE LIQUIDITY 0.58%</div>
              <div class="textSan">AIRDROPS 0.42%</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Team -->
    <div class="container teamWrap" id="Team">
      <div class="teamTitle">TEAM</div>
      <div class="teamCon">
        <div class="teamItem">
          <img
            v-lazy="require('@/assets/images/WARREN.png')"
            alt=""
            srcset=""
          />
          <p>WARREN</p>
          <p>
            Internationally experienced Commercial Executive leading the team to
            deliver future exciting developments to the Metaverse
          </p>
        </div>
        <div class="teamItem">
          <img
            v-lazy="require('@/assets/images/CHARMAINE.png')"
            alt=""
            srcset=""
          />
          <p>CHARMAINE</p>
          <p>Global Project Management and Facilitation Lead.</p>
        </div>
        <div class="teamItem">
          <img
            v-lazy="require('@/assets/images/OLIVERCOLE.png')"
            alt=""
            srcset=""
          />
          <p>OLIVER COLE</p>
          <p>Steeped in horse racing history, Champion Trainer.</p>
        </div>

        <div class="teamItem">
          <img v-lazy="require('@/assets/images/CRAIG.png')" alt="" srcset="" />
          <p>CRAIG</p>
          <p>Global Digital Marketing specialist.</p>
        </div>
        <!-- <div class="teamItem">
          <img v-lazy="require('@/assets/images/STEVE.png')" alt="" srcset="" />
          <p>STEVE</p>
          <p>{{ `Crypto, Blockchain & NFT specialist.` }}</p>
        </div> -->
        <div class="teamItem">
          <img v-lazy="require('@/assets/images/TEO.png')" alt="" srcset="" />
          <p>TEO</p>
          <p>{{ `NFT & Graphic Design, Sound and Vision specialist.` }}</p>
        </div>

        <div class="teamItem">
          <img v-lazy="require('@/assets/images/TOM.png')" alt="" srcset="" />
          <p>TOM</p>
          <p>{{ `Blockchain, VR & Trading specialist.` }}</p>
        </div>
        <div class="teamItem">
          <img
            v-lazy="require('@/assets/images/OLIVER.png')"
            alt=""
            srcset=""
          />
          <p>OLIVER</p>
          <p>Strong working background in the Gaming Industry.</p>
        </div>
        <div class="teamItem">
          <img v-lazy="require('@/assets/images/ALEX.png')" alt="" srcset="" />
          <p>ALEX</p>
          <p>{{ `Crypto Community specialist, NFT & Crypto enthusiast.` }}</p>
        </div>
        <div class="teamItem">
        </div>
      </div>
    </div>
    <div class="container milestone" id="Milestone">
      <div class="mileageTitle">MILESTONE</div>
      <div class="mileStep">
        <div class="stepList">
          <div class="itemWrap">
            <div class="itemTit">PHASE ONE</div>
            <div class="itemCon">
              <div>Launch NFT Racehorses</div>
              <div>Open NFT auction</div>
              <div>Open NFT mining</div>
              <div>Open match function</div>
              <div>Open R&D function</div>
            </div>
          </div>
          <div class="itemWrap">
            <div class="itemTit">PHASE THREE</div>
            <div class="itemCon">
              <div>Wagering function</div>
              <div>Sponsorship & Advertising</div>
            </div>
          </div>
        </div>
        <div class="numLine">
          <div class="stepNode"><div class="stepChild">01</div></div>
          <div class="stepNode"><div class="stepChild">02</div></div>
          <div class="stepNode"><div class="stepChild">03</div></div>
          <div class="stepNode"><div class="stepChild">04</div></div>
        </div>
        <div class="stepList">
          <div class="itemWrap">
            <div class="itemTit">PHASE TWO</div>
            <div class="itemCon">
              <div>Launch Jockeys</div>
              <div>Open jockey & accessory NFTs</div>
              <div>Open racecourse ownership NFTs</div>
            </div>
          </div>
          <div class="itemWrap">
            <div class="itemTit">PHASE FOUR</div>
            <div class="itemCon">
              <div>XR operation function (Extended Reality)</div>
              <div>Open customized racecourse construction function</div>
              <div>Open match schedule customization function</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container gameplay">
      <div class="container carousel-wrap">
        <div class="carouselTit">COMMUNITY</div>
        <div class="carouselBox">
          <el-carousel type="card" height="272px">
            <el-carousel-item
              v-for="(item, index) in coummunityImgsArr"
              :key="index"
            >
              <img v-lazy="item.imgUrl" alt />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HouseType from "@/components/HorsesType";
export default {
  components: {
    HouseType,
  },
  data() {
    return {
      logo: require("../assets/logo.png"),
      autoplay: false,
      show: true,
      coummunityImgsArr: [
        {
          imgUrl: require("../assets/images/Community-1.png"),
        },
        {
          imgUrl: require("../assets/images/Community-2.png"),
        },
        {
          imgUrl: require("../assets/images/Community-3.png"),
        },
      ],
      tebelData: [
        {
          name: "Lucas",
          algebra: "M01S00",
          num: "50",
          gene: "",
        },
        {
          name: "Paul",
          algebra: "M01S00",
          num: "50",
          gene: "",
        },
        {
          name: "Nick",
          algebra: "M01S00",
          num: "50",
          gene: "",
        },
        {
          name: "Mark",
          algebra: "M01S00",
          num: "50",
          gene: "",
        },
        {
          name: "Lucy",
          algebra: "M01S00",
          num: "50",
          gene: "",
        },
        {
          name: "Sam",
          algebra: "M01S00",
          num: "50",
          gene: "",
        },
      ],
    };
  },
  mounted() {
    this.$refs.videos.addEventListener("pause", this.handlePause);
    this.$refs.videos.addEventListener("play", this.actionPlay);
  },
  methods: {
    toGame(path) {
      this.$router.push({ path });
    },
    actionIconClick() {
      this.show = false;
      this.$refs.videos.play();
    },
    actionPlay() {
      this.show = false;
    },
    handlePause() {
      this.show = true;
    },
  },
};
</script>


<style lang="scss" scoped>
#Gameplay,
#Team,
#Milestone {
  padding-top: 128px;
  margin-top: -125px;
}
.container {
  width: 1200px;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  position: relative;
}

.homeBan {
  width: 100%;
  // padding: 0 100px;
  box-sizing: border-box;
  background: url("../assets/images/BannerBg.png") no-repeat;
  background-size: 100% 100%;
}

.metarace_wrap {
  height: 676px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .homeHouse {
    width: 479px;
    height: 583px;
  }
  .metaraceRight {
    display: flex;
    flex-direction: column;
    .metaraceTit {
      font-size: 36px;
      font-weight: 800;
      font-size: 105px;
      color: #ffffff;
      font-family: "Rubik-BoldItalic";
    }
    .metaExplain {
      width: 100%;
      font-size: 15px;
      margin-top: 34px;
      margin-bottom: 41px;
      line-height: 23px;
      max-width: 600px;
      .colorText {
        font-size: 17px;
        display: block;
        color: #c822ff;
      }
    }
    .startBtn {
      width: 220px;
      height: 54px;
      border: 3px solid #c822ff;
      color: #c822ff;
      line-height: 54px;
      font-size: 27px;
      text-align: center;
      cursor: pointer;
      font-family: Rubik-BoldItalic;
    }
  }
}

.video_wrap {
  width: 100%;
  height: 758px;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .action {
    width: 128px;
    height: 128px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    cursor: pointer;
    transform: translate(-50%, -50%);
  }
}
.gameplay {
  // height: 756px;
  width: 100%;
  padding-bottom: 92px;
  .carousel-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .carouselTit {
      margin-top: 92px;
      margin-bottom: 68px;
      font-size: 46px;
      font-family: "Rubik-BoldItalic";
    }
    .carouselBox {
      width: 1200px;
      min-width: 1200px;
      .el-carousel {
        .el-carousel__container {
          height: 280px;
          .el-carousel__item img {
            // width: 498px;
            // height: 249px;
            width: 100%;
            height: 100%;
          }
        }
        .el-carousel__indicators--outside {
          position: relative;
          top: 20px;
        }
      }
    }
  }
}

.goalWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  .goalTitle {
    font-size: 50px;
    font-family: "Rubik-BoldItalic";
    font-weight: normal;
    color: #c822ff;
    text-align: center;
    margin-bottom: 117px;
    span {
      font-size: 61px;
      color: #fff;
    }
  }
  .goalList {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .goaltem {
      display: flex;
      flex-direction: column;
      align-items: center;
      .imgCon {
        width: 272px;
        height: 253px;
        background: #3c0765;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .timeReward {
        margin-bottom: 19px;
        font-size: 32px;
        font-family: "Rubik-BoldItalic";
      }
      .stay {
        color: #c822ff;
        font-size: 19px;
        font-family: "Rubik-MediumItalic";
      }
    }

    .goaltem:nth-child(1),
    .goaltem:nth-child(3) {
      img {
        width: 268px;
        height: 306px;
        position: relative;
      }
      .timeReward {
        margin-top: 75px;
      }
    }
    .goaltem:nth-child(2) {
      .timeReward {
        margin-top: 77px;
      }
      img {
        width: 315px;
        height: 359px;
        position: relative;
      }
    }
  }
}
.doubleWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 141px;
  margin-bottom: 180px;
  box-sizing: border-box;
  .doubleTitle {
    color: #c822ff;
    font-size: 46px;
    text-align: center;
    font-family: "Rubik-BoldItalic";
    margin-bottom: 93px;
  }
  .typeCon {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .tokenType {
      display: flex;
      justify-content: flex-start;
      .leftImgBox {
        width: 369px;
        height: 408px;
        margin-right: 23px;
        font-size: 25px;
        font-family: "Rubik-BoldItalic";
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-right: 28px;
        padding-bottom: 26px;
        box-sizing: border-box;
        background: url("../assets/images/total1.png") no-repeat;
        background-size: 100% 100%;
      }
      .typeData {
        display: flex;
        flex-direction: column;
        font-size: 17px;
        font-family: "Rubik-MediumItalic";
        position: relative;
        .textOne {
          position: relative;
          top: -8px;
        }
        .textTwo {
          position: absolute;
          top: 14%;
          white-space: nowrap;
        }
        .textSan {
          position: absolute;
          top: 27%;
        }
        .textFour {
          position: absolute;
          bottom: 39%;
        }
        .textFive {
          position: absolute;
          bottom: 11%;
        }
        .textSix {
          position: absolute;
          bottom: -10px;
        }
      }
    }
    .tokenType:nth-child(2) {
      margin-left: 20px;

      .leftImgBox {
        width: 370px;
        height: 328px;
        margin-top: 17px;
        padding-bottom: 0;
        padding-right: 36px;
        background: url("../assets/images/total2.png") no-repeat;
        background-size: 100% 100%;
      }
      .typeData {
        margin-top: 47px;
        position: relative;

        .textOne {
          position: relative;
          top: 5px;
        }

        .textTwo {
          position: absolute;
          top: 60px;
        }
        .textSan {
          position: absolute;
          top: 108px;
          white-space: nowrap;
        }
      }
    }
  }
}

.teamWrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 182px;
  .teamTitle {
    text-align: center;
    font-size: 46px;
    font-family: "Rubik-BoldItalic";
    margin-bottom: 55px;
  }
  .teamCon {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1008px;
    margin: 0 auto;
    .teamItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      text-align: center;
      img {
        width: 240px;
        height: 240px;
        margin-bottom: 22px;
      }
      p {
        font-size: 25px;
        font-family: "Rubik-MediumItalic";
      }
      p:last-child {
        font-family: Rubik-Italic;
        font-size: 17px;
        margin: 28px 0;
      }
    }
  }
}

.milestone {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  .mileageTitle {
    font-size: 46px;
    font-family: "Rubik-BoldItalic";
    text-align: center;
    margin-bottom: 48px;
  }
  .mileStep {
    display: flex;
    flex-direction: column;
    .stepList {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 37px;
      .itemWrap {
        width: 48%;
        height: 234px;
        background: #3c0765;
        border-radius: 20px;
        padding: 18px 27px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .itemTit {
          color: #c822ff;
          font-size: 30px;
          font-family: "Rubik-BoldItalic";
        }
        .itemCon {
          font-size: 20px;
          font-family: "Rubik-Italic";
          margin-top: 12px;
          line-height: 30px;
        }
      }
    }
    .stepList:last-child {
      margin-top: 37px;
      .itemWrap:nth-child(2) {
        .itemCon {
          width: 100%;
        }
      }
    }

    .numLine {
      justify-content: space-around;
      display: flex;
      align-items: center;
      .stepNode {
        width: 56px;
        height: 56px;
        background: #efeff4;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .stepChild {
          width: 41px;
          height: 41px;
          background: #c822ff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-style: normal;
          font-family: PingFangSC-Semibold;
        }
      }
      .stepNode::after {
        content: "";
        position: absolute;
        height: 32px;
        color: #cfcfda;
        border-left: 2px dashed;
        left: 50%;
        top: -65%;
      }
      .stepNode::before {
        content: "";
        // color: #8F8F8F;
        // border-top: 4px dotted;
        position: absolute;
        height: 4px;
        width: 245px;
        top: 50%;
        left: 100%;
        background: url("../assets/images/dottedLine.png");
      }
      .stepNode:last-child::before {
        content: "";
        display: none;
      }
      .stepNode:nth-child(2n)::after {
        content: "";
        top: 100%;
      }
    }
  }
}

.raceRank,
.raceRank .rankTitle,
.raceRank .rankTabel {
  display: flex;
  flex-direction: column;
}
.raceRank {
  margin-top: 120px;

  .rankTitle {
    font-size: 79px;
    font-family: "Rubik-BoldItalic";
    margin-bottom: 36px;
    text-align: center;
    div:nth-child(2) {
      font-size: 46px;
      color: #c822ff;
    }
  }
  .dataChange {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 33px;
    li {
      height: 54px;
      width: 220px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Rubik-BoldItalic;
      border: 3px solid #c822ff;
      font-size: 27px;
      color: #fff;
      margin: 0 15px;
      cursor: pointer;
    }
  }
  .rankTabel {
    width: 100%;
    position: relative;
    .raceRankbg {
      width: 100%;
      height: 100%;
      background: url("../assets/images/triangBg.png") no-repeat;
      background-size: 100% 100%;
      color: #c822ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 88px;
      font-family: "Rubik-BoldItalic";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
    }
    .tabelHead {
      width: 100%;
      height: 38px;
      display: flex;
      color: #c822ff;
      font-size: 15px;
      background: #111;
      align-items: center;
      li {
        padding-left: 10px;
      }
      .rank {
        width: 104px;
      }
      .house {
        width: 297px;
      }
      .algebra {
        width: 170px;
      }
      .gene {
        flex: 1;
      }
      .integral {
        width: 193px;
      }
    }
    .tabelBody {
      display: flex;
      flex-direction: column;
      .tabelItem {
        background: #333;
        color: #a6a8ab;
        display: flex;
        align-items: center;
        font-size: 26px;
        font-family: "Rubik-MediumItalic";
        li:first-child {
          width: 94px;
          height: 70px;
          background: url("../assets/images/triangleBg.png") no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: 70px;
          position: relative;
          .trophy {
            width: 47px;
            height: 58px;
            position: absolute;
            left: 10px;
            top: 0;
          }
        }
        li:nth-child(2) {
          width: 287px;
          display: flex;
          align-items: center;
          margin-left: 37px;
          .user {
            width: 51px;
            height: 51px;
            margin-right: 22px;
          }
        }
        li:nth-child(3) {
          width: 150px;
        }
        li:nth-child(4) {
          flex: 1;
          height: 30px;
        }
        li:nth-child(5) {
          width: 193px;
        }
        li:nth-child(3),
        li:nth-child(4),
        li:nth-child(5) {
          border-left: 5px solid #222;
          padding-left: 15px;
        }
      }
      .tabelItem:nth-child(even) {
        background: #111;
      }
    }
  }
}
</style>
